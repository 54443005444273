<template>
  <div>
    <div v-if="!userLoggedIn && !loading">
      <public-profile />
    </div>
    <div v-else-if="userLoggedIn && !loading">
      <semi-public-profile />
    </div>
  </div>
</template>

<script>
import PublicProfile from '@/components/PublicProfile.vue'
import SemiPublicProfile from '@/components/SemiPublicProfile.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'profile',
  components: {
    PublicProfile,
    SemiPublicProfile
  },
  data() {
    return {
      loading: true
    }
  },
  async mounted() {
    await this.$store.dispatch('auth/getProfile')
    this.loading = false
  },
  computed: {
    ...mapState({ userLoggedIn: 'loggedIn' })
  }
}
</script>
