<template>
  <b-taginput
    open-on-focus
    clearable
    autocomplete
    v-model="val"
    field="name"
    maxtags="1"
    :allow-new="true"
    :data="suggestions"
    :placeholder="$t('invitationForm.emailPlaceholder')"
    @typing="delayFetchSuggestions"
    @input="setInput"
    @blur="enterNewTag"
    :before-adding="beforeAddingMember"
    :id="id"
    :disabled="disabled"
    :closable="!disabled"
    aria-label="person-selector"
  >
  </b-taginput>
</template>

<script>
import { searchPeople } from '@/requests/people'

export default {
  props: {
    value: {
      required: false
    },
    id: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      suggestions: [],
      val: [],
      delayedFetchTimeout: null
    }
  },
  methods: {
    setInput(input) {
      if (input.length === 1) {
        this.$emit('input', input[0].id)
      } else {
        this.$emit('input', null)
      }
    },
    async fetchSuggestions(query) {
      const people = await searchPeople(query)
      this.suggestions = people.map((person) => {
        return { name: `${person.first_name} ${person.last_name}`, id: person.id }
      })
    },
    delayFetchSuggestions: function (query) {
      if (this.delayedFetchTimeout !== null) {
        clearTimeout(this.delayedFetchTimeout)
      }

      this.delayedFetchTimeout = setTimeout(async () => {
        await this.fetchSuggestions(query)
        this.delayedFetchTimeout = null
      }, 400)
    },
    beforeAddingMember(tag) {
      if (!this.suggestions.includes(tag))
        this.$buefy.dialog.confirm({
          message: this.$t('personSelector.dialogConfirm'),
          confirmText: this.$t('personSelector.send'),
          cancelText: this.$t('personSelector.cancel'),
          onConfirm: () => {
            this.$emit('input', tag)
            this.$emit('newMemberAdded')
          },
          onCancel: () => {
            this.val = []
          }
        })
      return true
    },
    async enterNewTag(e) {
      setTimeout(() => {
        if (
          !e ||
          this.val.length >= 1 ||
          e.target.value === '' ||
          !this.beforeAddingMember(e.target.value)
        ) {
          return
        }
        this.val.push(e.target.value)
      }, 300)
    }
  },
  async mounted() {
    await this.fetchSuggestions(null)
    if (typeof this.value === 'number') {
      this.val = this.suggestions.filter((person) => person.id === this.value)
    } else if (typeof this.value === 'string' && this.value.length > 1) {
      this.val = [this.value]
    }
  }
}
</script>
