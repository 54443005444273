<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="
            project
              ? $t('divisionCreateForm.projectNameLabel')
              : $t('divisionCreateForm.divisionNameLabel')
          "
          :message="errors.name"
          :type="types.name"
        >
          <b-input aria-label="division-name" v-model="name" type="text"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="
            project
              ? $t('divisionCreateForm.projectShortNameLabel')
              : $t('divisionCreateForm.divisionShortNameLabel')
          "
          :message="errors.short_name"
          :type="types.short_name"
        >
          <b-input aria-label="division-short-name" v-model="short_name"></b-input>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="
            project
              ? $t('divisionCreateForm.projectDescriptionLabel')
              : $t('divisionCreateForm.divisionDescriptionLabel')
          "
          :message="errors.description"
          :type="types.description"
        >
          <b-input
            aria-label="division-description"
            v-model="description"
            type="textarea"
          ></b-input>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('project')

export default {
  name: 'ProjectCreateForm',
  props: {
    project: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['projectInformation']),
    ...mapState(['errors']),
    ...mapState(['types']),
    name: {
      get() {
        return this.projectInformation.name
      },
      set(value) {
        this.$store.commit('project/updateInformationValue', { field: 'name', value })
      }
    },
    short_name: {
      get() {
        return this.projectInformation.short_name
      },
      set(value) {
        this.$store.commit('project/updateInformationValue', { field: 'short_name', value })
      }
    },
    description: {
      get() {
        return this.projectInformation.description
      },
      set(value) {
        this.$store.commit('project/updateInformationValue', { field: 'description', value })
      }
    }
  }
}
</script>
