<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('views.projectCreate.startDate')"
          id="start-date-input"
          :message="errors.start_date"
          :type="types.start_date"
        >
          <b-datepicker
            aria-label="start-date"
            :placeholder="$t('form.clickToSelect')"
            v-model="start_date"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-field
          :label="$t('views.projectCreate.endDate')"
          :message="errors.end_date"
          :type="types.end_date"
          id="end-date-input"
        >
          <b-datepicker
            aria-label="end-date"
            :placeholder="$t('form.clickToSelect')"
            v-model="end_date"
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('project')

export default {
  name: 'ProjectDatesForm',
  computed: {
    ...mapState(['projectInformation']),
    ...mapState(['errors']),
    ...mapState(['types']),
    start_date: {
      get() {
        return this.projectInformation.start_date
      },
      set(value) {
        this.$store.commit('project/updateInformationValue', { field: 'start_date', value })
      }
    },
    end_date: {
      get() {
        return this.projectInformation.end_date
      },
      set(value) {
        this.$store.commit('project/updateInformationValue', { field: 'end_date', value })
      }
    }
  }
}
</script>
