<template>
  <div class="columns">
    <div class="column">
      <div class="card">
        <div class="card-content">
          <!-- Division parameters -->

          <div class="columns">
            <div class="column">
              <b-field
                :label="$t('divisionConfirm.name')"
                id="name-input"
                :message="errors.name"
                :type="types.name"
                label-for="confirm-name"
              >
                <b-input id="confirm-name" :value="projectInformation.name" disabled></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$t('divisionConfirm.shortName')"
                id="short-name-input"
                :message="errors.short_name"
                :type="types.short_name"
              >
                <b-input
                  aria-label="short-name-confirm"
                  :value="projectInformation.short_name"
                  disabled
                ></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$t('divisionConfirm.description')"
                :message="errors.description"
                :type="types.description"
                id="description-input"
              >
                <b-input
                  aria-label="description-confirm"
                  :value="projectInformation.description"
                  disabled
                  type="textarea"
                ></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-field
                :label="$t('views.projectCreate.startDate')"
                id="start-date-input"
                :message="errors.start_date"
                :type="types.start_date"
              >
                <b-datepicker
                  aria-label="start-date"
                  :placeholder="$t('form.clickToSelect')"
                  v-model="projectInformation.start_date"
                  disabled
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>

          <div class="columns" v-if="projectInformation.end_date">
            <div class="column">
              <b-field
                :label="$t('views.projectCreate.endDate')"
                :message="errors.end_date"
                :type="types.end_date"
                id="end-date-input"
              >
                <b-datepicker
                  aria-label="end-date"
                  :placeholder="$t('form.clickToSelect')"
                  v-model="projectInformation.end_date"
                  disabled
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>

          <!-- Head division of division -->
          <hr />
          <div v-if="adminInformation.person_id">
            <h3 class="subtitle is-5 has-roboto-slab">
              {{ $t('divisionConfirm.adminInfo') }}
            </h3>

            <div class="columns">
              <div class="column">
                <b-field
                  :label="$t('divisionConfirm.person')"
                  :message="errors.is_admin || errors.__root__"
                  :type="types.is_admin || errors.__root__"
                  id="head-division-selector"
                  label-for="head-division-selector-input"
                >
                  <person-selector
                    :value="adminInformation.person_id"
                    :disabled="true"
                    id="head-division-selector-input"
                  />
                </b-field>
              </div>
            </div>

            <div class="columns" v-if="!!adminInformation.membership_type_id">
              <div class="column">
                <b-field
                  :label="$t('divisionConfirm.membership')"
                  :message="errors.membership_type_id"
                  :type="types.membership_type_id"
                  id="membership-type-selector"
                  label-for="membership-type-selector-input"
                >
                  <membership-type-selector
                    :value="adminInformation.membership_type_id"
                    id="membership-type-selector-input"
                    :disabled="true"
                  />
                </b-field>
              </div>
            </div>

            <div class="columns" v-if="!!adminInformation.role_type_id">
              <div class="column">
                <b-field
                  :label="$t('divisionConfirm.roleInProject')"
                  :message="errors.role_type_id"
                  :type="types.role_type_id"
                >
                  <role-type-selector :value="adminInformation.role_type_id" :disabled="true" />
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonSelector from '@/components/PersonSelector'
import MembershipTypeSelector from '@/components/MembershipTypeSelector.vue'
import RoleTypeSelector from '@/components/RoleTypeSelector.vue'

import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('project')

export default {
  name: 'ProjectCreateConfirm',
  components: {
    PersonSelector,
    MembershipTypeSelector,
    RoleTypeSelector
  },
  computed: {
    ...mapState(['projectInformation']),
    ...mapState(['adminInformation']),
    ...mapState(['errors']),
    ...mapState(['types'])
  }
}
</script>
