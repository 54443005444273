<template>
  <div class="container mt-2">
    <div class="columns">
      <div class="column is-narrow" v-if="division">
        <b-button
          icon-left="arrow-left"
          tag="router-link"
          :to="{ name: 'division', params: { id: division.id } }"
        >
          <span class="has-text-azul-imfd">
            Volver a {{ division.short_name || division.name }}
          </span>
        </b-button>
      </div>
    </div>

    <h1 class="title has-roboto-slab">
      {{ $t('views.divisionCreate.title') }}
    </h1>

    <div class="columns">
      <div class="column is-7">
        <div class="card">
          <div class="card-content">
            <b-steps
              v-model="actualStep"
              :animated="true"
              :rounded="true"
              label-position="bottom"
              mobile-mode="minimalist"
            >
              <b-step-item step="1">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.divisionTitle') }}
                </h3>
                <division-create-form />
              </b-step-item>

              <b-step-item step="2">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.adminTitle') }}
                </h3>

                <div class="columns">
                  <div class="column">
                    <member-invitation-form />
                  </div>
                </div>
              </b-step-item>

              <b-step-item step="3">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.confirm') }}
                </h3>

                <division-create-confirm ref="divisionConfirm" />
              </b-step-item>

              <template #navigation="{ previous, next }">
                <div
                  class="buttons is-flex-direction-row"
                  :class="[
                    activeStep === 2 ? 'is-justify-content-center' : 'is-justify-content-right'
                  ]"
                >
                  <b-button
                    :disabled="previous.disabled || Object.keys(errors).length > 0"
                    @click.prevent="previous.action"
                  >
                    {{ $t('views.divisionCreate.buttonPrevious') }}
                  </b-button>
                  <b-button
                    type="is-azul-imfd"
                    :disabled="next.disabled || Object.keys(errors).length > 0"
                    @click="validateStepForm(next.action)"
                    v-if="activeStep !== 2"
                  >
                    {{ $t('views.divisionCreate.buttonNext') }}
                  </b-button>
                  <b-button @click="creationCanceled" v-if="activeStep === 2">{{
                    $t('personEditProfile.cancelButton')
                  }}</b-button>
                  <b-button
                    type="is-azul-imfd"
                    @click="onSubmit"
                    :disabled="Object.keys(errors).length > 0"
                    v-if="activeStep === 2"
                  >
                    {{ $t('views.divisionCreate.buttonCreate') }}
                  </b-button>
                </div>
              </template>
            </b-steps>
          </div>
        </div>
      </div>

      <div class="column is-5">
        <div class="card">
          <div class="card-content" v-if="division">
            <div class="media">
              <div class="media-left">
                <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
              </div>

              <div class="media-content">
                <i18n path="divisionCreateForm.parentDivisionLabel" tag="p">
                  <template #division>
                    <strong>{{ division.name }}</strong>
                  </template>
                </i18n>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DivisionCreateForm from '@/components/DivisionCreateForm.vue'
import DivisionCreateConfirm from '@/components/DivisionCreateConfirm.vue'
import { findDivision, createDivision } from '@/requests/divisions'
import MemberInvitationForm from '@/components/MemberInvitationForm.vue'
import { createNamespacedHelpers } from 'vuex'
import FormValidationError from '@/errors/FormValidationError'
import AppError from '@/errors/AppError'

const { mapState, mapMutations } = createNamespacedHelpers('division')

export default {
  name: 'DivisionCreate',
  components: {
    DivisionCreateForm,
    MemberInvitationForm,
    DivisionCreateConfirm
  },
  computed: {
    ...mapState(['divisionInformation']),
    ...mapState(['activeStep']),
    ...mapState(['adminInformation']),
    ...mapState(['errors']),
    actualStep: {
      get() {
        return this.activeStep
      },
      set(value) {
        this.$store.commit('division/nextStep', { value })
      }
    }
  },
  methods: {
    ...mapMutations(['validateForm']),
    validateStepForm(callback) {
      this.validateForm()
      if (Object.keys(this.errors).length === 0) {
        return callback()
      }
      return
    },
    async onSubmit() {
      const loading = this.$buefy.loading.open({
        container: this.$refs.divisionConfirm.$el
      })

      try {
        const newDivision = await createDivision({
          parent: this.$route.params.id,
          ...this.divisionInformation,
          ...this.adminInformation
        })
        return this.onSubmitSuccess(newDivision)
      } catch (err) {
        if (err instanceof FormValidationError) {
          this.$store.commit('division/onValidationError', { error: err })
        } else {
          this.onSubmitError(err)
        }
      } finally {
        loading.close()
      }
    },
    async onSubmitSuccess(createdObject) {
      this.$store.commit('division/resetStore')
      this.$buefy.snackbar.open({
        message: this.$t('divisionCreateForm.creationSuccess'),
        type: 'is-success',
        position: 'is-top',
        actionText: this.$t('divisionCreateForm.creationSuccessAction'),
        onAction: () => {
          this.$router.push({ name: 'division', params: { id: createdObject.id } })
        }
      })

      await this.$store.dispatch('auth/getProfile')
      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    onSubmitError(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })
      } else {
        throw new AppError(this.$t('messageError.somethingWrong'))
      }

      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    creationCanceled() {
      this.$store.commit('division/resetStore')
      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    }
  },
  data() {
    return {
      division: null
    }
  },
  async mounted() {
    this.division = await findDivision({ divisionId: this.$route.params.id })
  },
  destroyed() {
    this.$store.commit('division/resetStore')
  }
}
</script>
