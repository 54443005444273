<template>
  <div class="container mt-2">
    <h1 class="title has-roboto-slab">
      {{ $t('views.projectCreate.title') }}
    </h1>
    <div class="columns">
      <div class="column is-7">
        <div class="card">
          <div class="card-content">
            <b-steps
              v-model="actualStep"
              :animated="true"
              :rounded="true"
              label-position="bottom"
              mobile-mode="minimalist"
            >
              <b-step-item step="1">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.projectCreate.projectTitle') }}
                </h3>
                <project-create-form :project="true" />
                <project-dates-form />
              </b-step-item>

              <b-step-item step="2">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.adminTitle') }}
                </h3>

                <div class="columns">
                  <div class="column">
                    <member-invitation-form-project />
                  </div>
                </div>
              </b-step-item>

              <b-step-item step="3">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.confirm') }}
                </h3>
                <project-create-confirm ref="divisionConfirm" />
              </b-step-item>

              <template #navigation="{ previous, next }">
                <div
                  class="buttons is-flex-direction-row"
                  :class="[
                    activeStep === 2 ? 'is-justify-content-center' : 'is-justify-content-right'
                  ]"
                >
                  <b-button
                    :disabled="previous.disabled || Object.keys(errors).length > 0"
                    @click.prevent="previous.action"
                  >
                    {{ $t('views.projectCreate.buttonPrevious') }}
                  </b-button>
                  <b-button
                    type="is-azul-imfd"
                    :disabled="next.disabled || Object.keys(errors).length > 0"
                    @click="validateStepForm(next.action)"
                    v-if="activeStep !== 2"
                  >
                    {{ $t('views.projectCreate.buttonNext') }}
                  </b-button>
                  <b-button @click="creationCanceled" v-if="activeStep === 2">{{
                    $t('personEditProfile.cancelButton')
                  }}</b-button>
                  <b-button
                    type="is-azul-imfd"
                    @click="onSubmit"
                    :disabled="Object.keys(errors).length > 0"
                    v-if="activeStep === 2"
                  >
                    {{ $t('views.divisionCreate.buttonCreate') }}
                  </b-button>
                </div>
              </template>
            </b-steps>
          </div>
        </div>
      </div>
      <div class="column is-5">
        <v-promised :promise="divisionPromise">
          <template #pending>
            <b-loading :can-cancel="false"></b-loading>
          </template>
          <template v-slot="division">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                  </div>

                  <div class="media-content">
                    <i18n path="views.projectCreate.parentDivisionLabel" tag="p">
                      <template #division>
                        <strong>{{ division.name }}</strong>
                      </template>
                    </i18n>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #rejected="error">
            <v-fail :error="error" />
          </template>
        </v-promised>
      </div>
    </div>
  </div>
</template>

<script>
import { findDivision, createProject } from '@/requests/divisions'
import { toBackendFormat } from '@/utils/date-utils'
import ProjectCreateConfirm from '@/components/ProjectCreateConfirm.vue'
import ProjectCreateForm from '@/components/ProjectCreateForm.vue'
import MemberInvitationFormProject from '@/components/MemberInvitationFormProject.vue'
import ProjectDatesForm from '@/components/ProjectDatesForm.vue'
import clone from 'rfdc'

import { createNamespacedHelpers } from 'vuex'
import FormValidationError from '@/errors/FormValidationError'
import AppError from '@/errors/AppError'

const { mapState, mapMutations } = createNamespacedHelpers('project')

export default {
  name: 'ProjectCreate',
  computed: {
    ...mapState(['projectInformation']),
    ...mapState(['adminInformation']),
    ...mapState(['activeStep']),
    ...mapState(['errors']),
    actualStep: {
      get() {
        return this.activeStep
      },
      set(value) {
        this.$store.commit('project/nextStep', { value })
      }
    }
  },
  components: {
    ProjectCreateForm,
    MemberInvitationFormProject,
    ProjectCreateConfirm,
    ProjectDatesForm
  },
  methods: {
    ...mapMutations(['validateForm']),
    validateStepForm(callback) {
      this.validateForm()
      if (Object.keys(this.errors).length === 0) {
        return callback()
      }
      return
    },
    async onSubmit() {
      const loading = this.$buefy.loading.open({
        container: this.$refs.divisionConfirm.$el
      })
      try {
        const newProject = await createProject({
          parent: this.$route.params.id,
          ...this.projectInformation,
          ...this.changeDateFormat(this.projectInformation),
          ...this.adminInformation
        })
        return this.onSubmitSuccess(newProject)
      } catch (err) {
        if (err instanceof FormValidationError) {
          this.$store.commit('project/onValidationError', { error: err })
        } else {
          this.onSubmitError(err)
        }
      } finally {
        loading.close()
      }
    },
    async onSubmitSuccess(createdObject) {
      this.$store.commit('project/resetStore')
      this.$buefy.snackbar.open({
        message: this.$t('views.projectCreate.creationSuccess'),
        type: 'is-success',
        position: 'is-top',
        actionText: this.$t('views.projectCreate.creationSuccessAction'),
        onAction: () => {
          this.$router.push({ name: 'division', params: { id: createdObject.id } })
        }
      })
      await this.$store.dispatch('auth/getProfile')
      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    changeDateFormat(projectInfo) {
      const form = clone()(projectInfo)
      form.start_date = form.start_date ? toBackendFormat(form.start_date) : null
      form.end_date = form.end_date ? toBackendFormat(form.end_date) : null
      return form
    },
    onSubmitError(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })
      } else {
        throw new AppError(this.$t('messageError.somethingWrong'))
      }

      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    creationCanceled() {
      this.$store.commit('project/resetStore')
      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    }
  },
  data() {
    return {
      divisionPromise: null
    }
  },
  async mounted() {
    this.divisionPromise = findDivision({ divisionId: this.$route.params.id })
  },

  destroyed() {
    this.$store.commit('project/resetStore')
  }
}
</script>
