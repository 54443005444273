<template>
  <v-promised :promise="promises">
    <template #pending>
      <b-loading :can-cancel="false"></b-loading>
    </template>
    <template v-slot="[member, divisions, projects]">
      <div class="container margin">
        <div class="columns is-centered is-mobile">
          <div class="column is-narrow has-text-centered is-flex is-flex-direction-column">
            <figure
              class="image is-128x128 is-align-self-center"
              style="margin-inline-end: 0; padding: 0"
            >
              <img
                class="is-rounded"
                style="margin: 0 auto"
                :src="`${backendUrl}${member.avatar}`"
              />
            </figure>
            <div class="is-align-self-flex-end"></div>
            <div class="has-text-centered name-label">
              {{ `${member.first_name} ${member.last_name}` }}
            </div>
            <div class="has-text-centered position-label">{{ member.active_membership }}</div>
          </div>
        </div>

        <b-tabs position="is-centered" v-model="activeTab">
          <b-tab-item
            :label="$t('views.personProfile.personalInformationSemiPublic')"
            value="personalInfo"
          >
            <div class="columns is-centered">
              <div class="column is-6 is-12-mobile">
                <person-card :person="member" :disableEdition="true" />
              </div>
            </div>
          </b-tab-item>

          <b-tab-item
            :label="$t('views.personProfile.associatedDivisionsSemiPublic')"
            value="divisions"
          >
            <div class="columns is-multiline" v-if="divisions.length > 0">
              <div
                class="column is-4"
                v-for="division of divisions"
                :key="`division${division.id}`"
              >
                <division-card
                  :division="division"
                  :role="division.role_type || member.active_membership"
                />
              </div>
            </div>

            <div class="is-flex is-flex-direction-column mt-4 mb-4" v-else>
              <div class="is-align-self-center">
                <img id="no-division" src="@/assets/images/sin_divisiones.png" />
              </div>
              <div class="is-align-self-center">
                <p v-html="$t('divisionDetailBody.noSubdivisions')"></p>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            :label="$t('views.personProfile.associatedProjectsSemiPublic')"
            value="projects"
          >
            <div class="columns is-multiline" v-if="projects.length > 0">
              <div class="column is-4" v-for="project of projects" :key="`project${project.id}`">
                <division-card :division="project" :role="project.role_type" />
              </div>
            </div>

            <div class="is-flex is-flex-direction-column mt-4 mb-4" v-else>
              <div class="is-align-self-center">
                <img id="no-division" src="@/assets/images/sin_proyectos.png" />
              </div>
              <div class="is-align-self-center">
                <p v-html="$t('divisionDetailBody.noProjects')"></p>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </template>
    <template #rejected="error">
      <v-fail :error="error" />
    </template>
  </v-promised>
</template>

<script>
import PersonCard from '@/components/PersonCard.vue'
import DivisionCard from '@/components/DivisionCard.vue'
import { findMemberDivisions, findMemberProjects, findMember } from '@/requests/people'

export default {
  name: 'SemiPublicProfile',
  components: {
    PersonCard,
    DivisionCard
  },
  mounted() {
    this.promises = Promise.all([
      findMember({ userId: this.$route.params.id }),
      findMemberDivisions({ userId: this.$route.params.id }),
      findMemberProjects({ userId: this.$route.params.id })
    ])
  },
  data() {
    return {
      promises: null
    }
  },
  computed: {
    backendUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return url.replace(/\/$/, '')
    },
    activeTab: {
      set(val) {
        const query = { ...this.$route.query }
        query.tab = val
        if (val !== 'personalInfo') {
          this.editProfile = false
        }

        this.$router.replace({ query: query })
      },
      get() {
        return this.$route.query.tab || 'personalInfo'
      }
    }
  }
}
</script>
