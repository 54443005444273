<template>
  <v-promised :promise="memberProfilePromise">
    <template #pending>
      <b-loading :can-cancel="false"></b-loading>
    </template>
    <template v-slot="member">
      <div class="container mt-2">
        <div class="columns is-centered">
          <div class="column is-narrow has-text-centered">
            <figure class="image is-128x128" style="margin-inline-end: 0; padding: 0">
              <img
                class="is-rounded"
                style="margin: 0 auto"
                src="https://bulma.io/images/placeholders/128x128.png"
              />
            </figure>
            <div class="has-text-centered name-label">
              {{ `${member.first_name} ${member.last_name}` }}
            </div>
            <div class="has-text-centered position-label">{{ member.active_membership }}</div>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-6 is-12-mobile">
            <person-card :person="member" :disableEdition="true" />
          </div>
        </div>
      </div>
    </template>
    <template #rejected="error">
      <v-fail :error="error" />
    </template>
  </v-promised>
</template>

<script>
import PersonCard from '@/components/PersonCard.vue'
import { findPublicMember } from '@/requests/people'

export default {
  name: 'PublicProfile',
  components: {
    PersonCard
  },
  data() {
    return {
      memberProfilePromise: null
    }
  },
  mounted() {
    this.memberProfilePromise = findPublicMember({ userId: this.$route.params.id })
  }
}
</script>
