<template>
  <div class="container">
    <h1 class="title">
      {{ $t('views.membershipCreate.title') }}
    </h1>

    <v-form
      :on-submit="onSubmit"
      :on-submit-success="onSubmitSuccess"
      :on-submit-error="onSubmitError"
      :initial-value="{ division: division.id }"
      v-if="division"
      ref="membershipCreateForm"
    >
      <template v-slot="{ form }">
        <div class="columns is-vcentered">
          <div class="column is-6">
            <b-field
              :label="$t('membershipCreateForm.personLabel')"
              :message="form.errors.person_id || form.errors.invitee_email"
              :type="form.types.person_id || form.types.invitee_email"
            >
              <person-selector v-model="form.values.person_id" @newMemberAdded="newMember = true" />
            </b-field>
          </div>

          <!-- Permissions -->
          <div class="column is-6">
            <b-field>
              <b-checkbox v-model="form.values.is_admin">
                {{ $t('membershipCreateForm.makeAdmin') }}
              </b-checkbox>
            </b-field>
          </div>
        </div>

        <!-- Type of membership -->
        <div class="columns is-vcentered" v-if="newMember">
          <div class="column is-6">
            <b-field
              :label="$t('membershipCreateForm.membershipTypeLabel')"
              :message="form.errors.membership_type"
              :type="form.types.membership_type"
            >
              <membership-type-selector v-model="form.values.membership_type_id" />
            </b-field>
          </div>
        </div>

        <!-- Type of role -->
        <div class="columns is-vcentered">
          <div class="column is-6">
            <b-field
              :label="
                division.is_project
                  ? $t('membershipCreateForm.projectRoleTypeLabel')
                  : $t('membershipCreateForm.divisionRoleTypeLabel')
              "
              :message="form.errors.membership_type"
              :type="form.types.membership_type"
            >
              <role-type-selector v-model="form.values.role_type_id" />
            </b-field>
          </div>
        </div>

        <!-- Division -->
        <b-field :label="$t('membershipCreateForm.divisionLabel')">
          <div class="control">
            {{ $data.division.name }}
          </div>
        </b-field>

        <b-button native-type="submit">{{ $t('membershipCreateForm.button') }}</b-button>
      </template>
    </v-form>
  </div>
</template>

<script>
import VForm from '@/components/VForm'
import PersonSelector from '@/components/PersonSelector'
import RoleTypeSelector from '@/components/RoleTypeSelector'
import MembershipTypeSelector from '@/components/MembershipTypeSelector'
import { findDivision } from '@/requests/divisions'
import { createMembership, addMemberToDivision } from '@/requests/memberships'

export default {
  name: 'MembershipCreate',
  components: {
    PersonSelector,
    RoleTypeSelector,
    MembershipTypeSelector,
    VForm
  },

  methods: {
    async onSubmit(form) {
      const loading = this.$buefy.loading.open({
        container: this.$refs.membershipCreateForm.$el
      })

      try {
        if (typeof form.person_id === 'number') {
          return await addMemberToDivision(form)
        } else {
          form.invitee_email = form.person_id
          delete form.person_id
          return await createMembership(form)
        }
      } finally {
        loading.close()
      }
    },
    onSubmitSuccess(response) {
      this.$buefy.toast.open({
        message: response.invitee_email
          ? this.$t('membershipCreateForm.invitationSuccess')
          : this.$t('membershipCreateForm.creationSuccess'),
        type: 'is-success'
      })

      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    onSubmitError(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })

        this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
      }
    }
  },
  data() {
    return {
      division: null,
      newMember: false
    }
  },
  async mounted() {
    const division = await findDivision({ divisionId: this.$route.params.id })
    this.division = division
  }
}
</script>
