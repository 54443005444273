<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <b-field
          :label="$t('membershipCreateForm.personLabel')"
          :message="errors.person_id || errors.invitee_email"
          :type="types.person_id || types.invitee_email"
        >
          <person-selector
            v-model="person_id"
            @newMemberAdded="
              () => {
                newMember = true
              }
            "
          />
        </b-field>
      </div>
    </div>

    <!-- Type of membership -->
    <div class="columns is-vcentered" v-if="newMember">
      <div class="column">
        <b-field
          :label="$t('membershipCreateForm.membershipTypeLabel')"
          :message="errors.membership_type_id"
          :type="types.membership_type_id"
        >
          <membership-type-selector ref="membershipType" v-model="membership_type_id" />
        </b-field>
      </div>
    </div>

    <!-- Type of role -->
    <div class="columns is-vcentered">
      <div class="column">
        <b-field
          :label="$t('membershipCreateForm.divisionRoleTypeLabel')"
          :message="errors.role_type_id"
          :type="types.role_type_id"
        >
          <role-type-selector ref="roleType" v-model="role_type_id" />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import PersonSelector from '@/components/PersonSelector'
import RoleTypeSelector from '@/components/RoleTypeSelector'
import MembershipTypeSelector from '@/components/MembershipTypeSelector'

import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('division')

export default {
  name: 'MembershipInvitationForm',
  components: {
    PersonSelector,
    RoleTypeSelector,
    MembershipTypeSelector
  },
  data() {
    return {
      division: null,
      newMember: null
    }
  },
  computed: {
    ...mapState(['adminInformation']),
    ...mapState(['errors']),
    ...mapState(['types']),
    person_id: {
      get() {
        return this.adminInformation.person_id
      },
      set(value) {
        this.$store.commit('division/updateAdministratorValue', { field: 'person_id', value })
      }
    },
    membership_type_id: {
      get() {
        return this.adminInformation.membership_type_id
      },
      set(value) {
        this.$store.commit('division/updateAdministratorValue', {
          field: 'membership_type_id',
          value
        })
      }
    },
    role_type_id: {
      get() {
        return this.adminInformation.role_type_id
      },
      set(value) {
        this.$store.commit('division/updateAdministratorValue', {
          field: 'role_type_id',
          value
        })
      }
    }
  }
}
</script>
